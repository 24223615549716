import axios from './../utlis/axios'



///////////////////////////////////////////
/////////////////成就//////////////////
export async function achievementList(params) {
    try {
        let {data} = await axios.get('/admin/achievementList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//成就列表-不分页
export async function achievementLists(params) {
    try {
        let {data} = await axios.get('/admin/achievementLists', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//修改
export async function achievementSave(params) {
    try {
        let {data} = await axios.post('/admin/achievementSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//添加
export async function achievementCreate(params) {
    try {
        let {data} = await axios.post('/admin/achievementCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//删除
export async function achievementDel(params) {
    try {
        let {data} = await axios.post('/admin/achievementDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//成就排行榜
export async function getAchievementRankApi(params) {
    try {
        let {data} = await axios.get('/admin/rank/getAchievementRank', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


