import axios from './../utlis/axios'


/**
 * D登录
 * @param params
 * @returns {Promise<*[]>}
 */
export async function login(params) {

    try {
        let {data} = await axios.post('/admin/auth/login', params);

        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

/**
 * 注册
 * @param params
 * @return {*}
 */
// 用户登录信息
export async function register(params) {
    try {
        let {data} = await axios.post('/admin/adminRegister', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
// 获取用户信息
export async function getStoreTimeInfoApi(params) {
    try {
        let {data} = await axios.post('/admin/common/getStoreTimeInfo', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
// 获取
export async function getUniqueStoreThemeApi(params) {
    try {
        let {data} = await axios.get('/admin/common/getUniqueStoreTheme', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
export async function getCode(params) {
    try {
        let {data} = await axios.post('/admin/getCode', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}



/**
 * 用户
 * @param params
 * @return {*}
 */
// 用户登录信息
export async function userMe(params) {
    try {
        let {data} = await axios.get('/admin/auth/me', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

// 用户列表
export async function userList(params) {
    try {
        let {data} = await axios.get('/admin/user/list', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

// 账户金额流水 /user/userMoneyLog
export async function userMoneyLog(params) {
    try {
        let {data} = await axios.get('/admin/user/wallet/log', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//用户信息
export async function userRegDetails(params) {
    try {
        let {data} = await axios.get('/admin/user/info', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//用户修改
export async function saveUser(params) {
    try {
        let {data} = await axios.post('/admin/user/save', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//用户添加
export async function createUser(params) {
    try {
        let {data} = await axios.post('/admin/user/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//我的团队
export async function userTeamLog(params) {
    try {
        let {data} = await axios.get('/admin/user/team', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//导入账号
export async function createExcel(params) {
    try {
        let {data} = await axios.post('/admin/user/excel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//推荐列表
export async function userRecommend(params) {
    try {
        let {data} = await axios.get('/admin/userRecommend', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改绑定
export async function saveUserRecommend(params) {
    try {
        let {data} = await axios.post('/admin/save/userRecommend', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//短信提醒
export async function userSms(params) {
    try {
        let {data} = await axios.post('/admin/weeks', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//序列
export async function excelColumns(params) {
    try {
        let {data} = await axios.get('/admin/excel_columns', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//渠道审核
export async function userRoleStatus(params) {
    try {
        let {data} = await axios.post('/admin/userRoleStatus', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//设置顶级
export async function saveTop(params) {
    try {
        let {data} = await axios.post('/admin/saveTop', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//修改积分
export async function saveWallet(params) {
    try {
        let {data} = await axios.post('/admin/saveWallet', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


/**
 * 公司
 * @param params
 * @returns {Promise<*[]>}
 */
export async function companyList(params) {
    try {
        let {data} = await axios.get('/admin/companyList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
/**
 * 公司
 * @param params
 * @returns {Promise<*[]>}
 */
export async function company(params) {
    try {
        let {data} = await axios.get('/admin/company', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加
export async function addCompany(params) {
    try {
        let {data} = await axios.post('/admin/addCompany', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑
export async function editCompany(params) {
    try {
        let {data} = await axios.post('/admin/editCompany', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除
export async function delCompany(params) {
    try {
        let {data} = await axios.post('/admin/delCompany', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//赠送列表
export async function storeCouponList(params) {
    try {
        let {data} = await axios.post('/admin/storeCouponList', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
