import axios from './../utlis/axios'
import {routerAddList} from './../router/index'
import store from './../store/index'
import mockData from './mockData'

let data = {
    data: mockData
};

/**
 * 过滤路由
 * @param router 源数据
 * @param temp 过滤模板
 * @return {*}
 */
function filterRouter(router = [], temp = []) {
    return router.reduce((ary, item) => {
        temp.forEach(it => {
            if (it.path === item.path) {
                if (it.children && it.children.length > 0 && item.children && item.children.length > 0) {
                    item.children = filterRouter(item.children, it.children);
                } else {
                    item.children = []
                }
                item.btn = it.btn || [];
                ary.push(item)
            }
        });
        return ary
    }, [])
}

const layout = {
    namespaced: true,
    state: {
        menuWidth: 200,
        isCollapse: false,
        device: 'desktop',
        menuList: []
    },
    actions: {
        /**
         * 菜单收起隐藏
         * @param commit
         * @param payload
         */
        toggleSideBar({commit}, payload) {
            let width = 200;
            if (payload) {
                width = 60;
            }
            commit('toggleSideBar', payload);
            commit('changeWidth', width);
        },
        /**
         * 设备判断
         * @param commit
         * @param state
         * @param payload
         */
        toggleDevice({commit, state}, payload) {
            if (payload === 'mobile') {
                commit('changeWidth', 60);
                commit('toggleSideBar', true);
            } else {
                commit('changeWidth', state.isCollapse ? 60 : 200);
            }
            commit('toggleDevice', payload);
        },
        /**
         * 获取登录用户的菜单
         * @param commit
         * @param dispatch
         * @param state
         * @param params
         * @return {Promise<*[]>}
         */
        async getListByUserApi({commit, dispatch, state}, params) {
            try {
                let {data} = await axios.get(`/admin/menu`, {params});


                routerAddList.children = filterRouter(routerAddList.children, data.data);
                console.log(data.data);
                routerAddList.children.forEach(item => {
                    if (item.children && item.children.length) {
                        item.redirect = item.children[0].path;
                        item.children.forEach(it => {
                            if (it.children && it.children.length) {
                                it.redirect = it.children[0].path;
                            }
                        });
                    }
                });
                routerAddList.redirect = routerAddList.children[0].path;
                commit('updateMenuList', routerAddList.children);
                return [null, [routerAddList]]
            } catch (e) {
                return [e, null]
            }
        }
    },
    mutations: {
        toggleSideBar(state, data) {
            state.isCollapse = data
        },
        toggleDevice(state, data) {
            state.device = data
        },
        changeWidth(state, data) {
            state.menuWidth = data
        },
        updateMenuList(state, data) {
            state.menuList = data
        }
    }
};
export default layout
