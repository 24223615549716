export default {
    path: '/system',
    name: 'system',
    icon: 'icon-neirong',
    component: () => import('../views/system/index'),
    title: '内容管理',
    children: [



    ]
}
