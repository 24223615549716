import axios from './../utlis/axios'


/**
 * 关于我们
 * @param params
 * @returns {Promise<*[]>}
 */
export  async  function  getAbout(params) {
    try {
        let {data} = await axios.get('/admin/about', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

/**
 * 修改关于我们
 * @param params
 * @returns {Promise<*[]>}
 */
export  async  function  saveAbout(params) {
    try {
        let {data} = await axios.post('/admin/save/about', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

/**
 * 权限列表
 * @param params
 * @returns {Promise<T[]|*[]>}
 */
export async function permissionListApi(params) {
    try {
        let {data} = await axios.get('/admin/menu/list-all', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


/**
 * 增加权限
 * @param params
 * @returns {Promise<T[]|*[]>}
 */
export async function permissionAddApi(params) {
    try {
        let {data} = await axios.post('/admin/menu/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}





/**
 * 获取设置内容
 * @param params
 * @returns {Promise<T[]|*[]>}
 */
export async function getWebSet(params) {
    try {
        let {data} = await axios.get('/admin/web/set', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
/**
 * 修改设置内容
 * @param params
 * @returns {Promise<T[]|*[]>}
 */
export async function saveWebSet(params) {
    try {
        let {data} = await axios.post('/admin/save/web/set', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//广告
export async function activeBanner(params) {
    try {
        let {data} = await axios.get('/admin/activeBanner', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑广告
export async function activeBannerSave(params) {
    try {
        let {data} = await axios.post('/admin/activeBannerSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


