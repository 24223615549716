import axios from './../utlis/axios'





// 游玩订单列表
export async function orderList(params) {
    try {
        let {data} = await axios.get('/admin/orderList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
// 游玩订单列表
export async function orderGoods(params) {
    try {
        let {data} = await axios.get('/admin/orderGoods', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//充值设置
export async function goodsList(params) {
    try {
        let {data} = await axios.get('/admin/goodsList', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加
export async function goodsCreate(params) {
    try {
        let {data} = await axios.post('/admin/goodsCreate', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改
export async function goodsSave(params) {
    try {
        let {data} = await axios.post('/admin/goodsSave', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除
export async function goodsDel(params) {
    try {
        let {data} = await axios.post('/admin/goodsDel', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//提成设置
export async function commission(params) {
    try {
        let {data} = await axios.get('/admin/commission', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改
export async function saveCommission(params) {
    try {
        let {data} = await axios.post('/admin/saveCommission', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//数据统计
export async function orderData(params) {
    try {
        let {data} = await axios.get('/admin/orderData', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//主题统计
export async function themesData(params) {
    try {
        let {data} = await axios.get('/admin/themesData', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//优惠券列表
export async function couponList(params) {
    try {
        let {data} = await axios.get('/admin/couponList', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//优惠券列表不分页
export async function couponDate(params) {
    try {
        let {data} = await axios.get('/admin/couponDate', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//赠送优惠券
export async function giveCoupon(params) {
    try {
        let {data} = await axios.post('/admin/giveCoupon', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//添加优惠券
export async function couponAdd(params) {
    try {
        let {data} = await axios.post('/admin/couponAdd', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑优惠券
export async function couponSave(params) {
    try {
        let {data} = await axios.post('/admin/couponSave', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除优惠券
export async function couponDel(params) {
    try {
        let {data} = await axios.post('/admin/couponDel', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//领取列表
export async function userCoupon(params) {
    try {
        let {data} = await axios.get('/admin/userCoupon', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//订单核销
export async function orderCheck(params) {
    try {
        let {data} = await axios.post('/admin/orderCheck', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//订单创建
export async function createdOrder(params) {
    try {
        let {data} = await axios.post('/admin/createdOrder', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//订单创建
export async function getTimeList(params) {
    try {
        let {data} = await axios.get('/admin/getTime', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//未处理的订单
export async function orderDispose(params) {
    try {
        let {data} = await axios.get('/admin/orderDispose', {params} );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//处理的订单
export async function ordersaveDispose(params) {
    try {
        let {data} = await axios.post('/admin/orderSave', params );
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取积分商品列表
export async function getIntegralGoodsListApi(params) {
    try {
        let {data} = await axios.get('/admin/integral/getIntegralGoodsList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加积分商品
export async function addIntegralGoodsApi(params) {
    try {
        let {data} = await axios.post('/admin/integral/addIntegralGoods', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑积分商品
export async function editIntegralGoodsApi(params) {
    try {
        let {data} = await axios.post('/admin/integral/editIntegralGoods', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//商品兑换开关
export async function switchIntegralGoodsApi(params) {
    try {
        let {data} = await axios.post('/admin/integral/switchIntegralGoods', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除积分商品
export async function delIntegralGoodsApi(params) {
    try {
        let {data} = await axios.post('/admin/integral/delIntegralGoods', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取兑换记录列表
export async function getConvertLogListApi(params) {
    try {
        let {data} = await axios.get('/admin/integral/getConvertLogList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//处理兑换记录
export async function handleConvertLogApi(params) {
    try {
        let {data} = await axios.post('/admin/integral/handleConvertLog', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

// 游玩订单列表
export async function getHandleExchangeNum(params) {
    try {
        let {data} = await axios.get('/admin/common/getHandleExchangeNum', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
// 直接退款
export async function refundOrder(params) {
    try {
        let {data} = await axios.post('/admin/refundOrder', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}