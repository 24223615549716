export default {
	path: '/theme',
	name: 'product',
	icon: 'icon-chanpin',
	component: () => import('../views/theme/index'),
	title: '主题管理',
	children: [{
			path: '/theme/list',
			name: 'list',
			icon: 'el-icon-user-solid',
			component: () => import('../views/theme/list'),
			title: '主题列表',
		},
		{
			path: '/theme/store',
			name: 'store',
			icon: 'el-icon-user-solid',
			component: () => import('../views/theme/storeTheme'),
			title: '店铺主题',
		},
		{
			path: '/theme/content',
			name: 'content',
			icon: 'el-icon-user-solid',
			component: () => import('../views/theme/content'),
			title: '店铺主题',
		}
	]
}
