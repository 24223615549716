<template>
    <div class="hg-dialog" v-if="value" @click="handClose">
        <div class="hg-dialog-content" @click.stop="" :style="{width: `${width}px`}">
            <div class="hg-dialog-head" :class="{'head-1':headType===1,'head-2':headType===2, 'head-border':headBorder}">
                <div class="hg-dialog-head-title">{{title}}</div>
                <div class="head-sub-title">
                    <slot name="subtitle"></slot>
                </div>
                <div class="head-sub">
                    <slot name="sub"></slot>
                </div>
                <div class="hg-dialog-head-close" @click="$emit('input', false)">×</div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false
            },
            width:{
                type: Number,
                default: 800
            },
            headType:{
                type: Number,
                default: 1
            },
            title:{
                type: String,
                default: ''
            },
            maskClose:{
                type: Boolean,
                default: true
            },
            headBorder:{
                type: Boolean,
                default: true
            }
        },
        name: "HgDialog",
        methods: {
            handClose() {
                if(this.maskClose){
                    this.$emit('input', false)
                }
            }
        },
    }
</script>

<style scoped lang="less">
    .hg-dialog{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.5);
        z-index: 999;
        .hg-dialog-content{
            width: 800px;
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;
            .hg-dialog-head{
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 38px;
                box-sizing: border-box;
                .hg-dialog-head-title{
                    font-size:18px;
                    font-weight:bold;
                }
                .head-sub-title{
                    margin-left: 20px;
                    margin-right: auto;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(104,109,123,1);
                }
                .head-sub{
                    margin-left: auto;
                    margin-right: 20px;
                }
                .hg-dialog-head-close{
                    font-size: 26px;
                    font-weight: bold;
                    cursor: pointer;

                }
            }
            .head-border{
                border:1px solid rgba(166, 173, 188, 0.35);
            }
            .head-1{
                background-color: #F6FAFF;
                .hg-dialog-head-close{
                    color: #CFD3D8;
                }
            }
            .head-2{
                background-color: #00B9D9;
                color: white;
                .hg-dialog-head-close{
                    color: #0097B1;
                }
            }
        }
    }
</style>
