export default {
    path: '/room',
    name: 'room',
    icon: 'icon-chanpin',
    component: () => import('../views/room/index'),
    title: '房间管理',
    children: [
        {
        path: '/room/list',
        name: 'list',
        icon: 'el-icon-user-solid',
        component: () => import('../views/room/list'),
        title: '房间列表',
    }
    ]
}
