import axios from './../utlis/axios'

////////////////////////////////////////////////////////////////
/**
 * 会员等级列表
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getVipListApi(params) {
    try {
        let {data} = await axios.get('/admin/vip/getVipList', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 添加会员等级
 * @param params
 * @returns {Promise<*[]>}
 */
export async function addVipApi(params) {
    try {
        let {data} = await axios.post('/admin/vip/addVip', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 编辑会员等级
 * @param params
 * @returns {Promise<*[]>}
 */
export async function editVipApi(params) {
    try {
        let {data} = await axios.post('/admin/vip/editVip', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 删除会员等级
 * @param params
 * @returns {Promise<*[]>}
 */
export async function delVipApi(params) {
    try {
        let {data} = await axios.post('/admin/vip/delVip', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 获取所有会员
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getVipAllApi(params) {
    try {
        let {data} = await axios.get('/admin/common/getVipAll', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 获取王卡列表
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getKingCardListApi(params) {
    try {
        let {data} = await axios.get('/admin/king/getKingCardList', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 添加王卡
 * @param params
 * @returns {Promise<*[]>}
 */
export async function addKingCardApi(params) {
    try {
        let {data} = await axios.post('/admin/king/addKingCard', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 编辑王卡
 * @param params
 * @returns {Promise<*[]>}
 */
export async function editKingCardApi(params) {
    try {
        let {data} = await axios.post('/admin/king/editKingCard', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 删除王卡
 * @param params
 * @returns {Promise<*[]>}
 */
export async function delKingCardApi(params) {
    try {
        let {data} = await axios.post('/admin/king/delKingCard', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 导入王卡
 * @param params
 * @returns {Promise<*[]>}
 */
export async function importKingCardApi(params) {
    try {
        let {data} = await axios.post('/admin/import/importKingCard', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}