<template>
    <div class="layout-right-nav">
        <div class="mr-auto nav-address"></div>
        <el-popover
                placement="bottom"
                width="400"
                v-model="visible"
                trigger="click">
            <i slot="reference" class="iconfont icon-sousuo mlr-20"></i>
            <div class="search-content">
                <el-input v-model="searchText" placeholder="输入关键字搜索页面"></el-input>
                <div class="search-list">
                    <div class="search-list-item" v-for="(item,index) in searchList" @click="toPage(item)" :key="index">
                        <template v-if="item.p_title && item.p_title.length">
                            <div v-for="(it,key) in item.p_title"
                                 :key="key"
                                 v-html="it.replace(`${searchText}`,`<span class='select-search-text'>${searchText}</span>`)+'-'"></div>
                        </template>
                        <div v-html="item.title.replace(`${searchText}`,`<span class='select-search-text'>${searchText}</span>`)"></div>
                        <i class="el-icon-arrow-right ml-auto"></i>
                    </div>
                </div>
            </div>
        </el-popover>
        <!--<el-badge class="mlr-20" style="margin-right: 30px;" :value="1">-->
            <!--<i class="iconfont icon-tongzhi"></i>-->
        <!--</el-badge>-->

        <!--<el-dropdown @command="handlerCommand">-->
            <!--&lt;!&ndash;<div class="nav-head">&ndash;&gt;-->
                <!--&lt;!&ndash;<img src="../../assets/logo1.png" alt="">&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--<el-dropdown-menu slot="dropdown">-->
                <!--<el-dropdown-item icon="el-icon-edit" command="editInfo">修改登录信息</el-dropdown-item>-->
                <!--<el-dropdown-item icon="el-icon-edit" command="editPsd">修改密码</el-dropdown-item>-->
            <!--</el-dropdown-menu>-->

        <!--</el-dropdown>-->
        <div style="text-align: center" class="iconfont   icon-tuichu" @click="loginOut"></div>
        <el-tooltip class="item" effect="dark" :disabled="isMobile" :content="userInfo.nickname" placement="bottom">

            <div>{{userInfo.nickname}}</div>
        </el-tooltip>

        <div class="nav-line nav-line-last"></div>
        <!--<i class="iconfont icon-icon-out-light mlr-20" title="退出登录" ></i>-->

        <HgDialog v-model="showEditInfo" title="修改登录信息" :width="500">
            <el-form class="dialog-form-content" :model="infoForm" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="姓名" prop="name" required>
                    <el-input v-model="infoForm.name" style="width: 220px;" placeholder="请填写新姓名"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="newUserName" required>
                    <el-input v-model="infoForm.newUserName" style="width: 220px;" placeholder="请填写新的登录账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" v-loading="editing" @click="editInfo">确认修改</div>
                </el-form-item>
            </el-form>
        </HgDialog>
        <HgDialog v-model="showEditPsd" title="修改密码" :width="500">
            <el-form class="dialog-form-content" :model="infoForm" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="密码" prop="newPassword" required>
                    <el-input v-model="infoForm.newPassword" style="width: 220px;" placeholder="请填写新密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" v-loading="editing" @click="editPsd">确认修改</div>
                </el-form-item>
            </el-form>
        </HgDialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {delToast, formVerify} from './../../utlis/decorators'
    import jsonp from 'jsonp'

    export default {
        name: "LayoutNav",
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                visible: false,
                editing: false,
                showEditInfo: false,
                showEditPsd: false,
                searchText: '',
                ah: [],
                infoForm: {
                    name: null,
                    newUserName: null,
                    newPassword: null
                },
                currentCity: '',
                weather: ''
            }
        },
        computed: {
            ...mapState('layout', {
                menuList: state => state.menuList,
            }),
            ...mapState('user', {
                userInfo: state => state.userInfo,
            }),
            totalUnread() {
                let list = this.menuList.find(it => it.path === '/message');
                if (list) {
                    let child = list.children;
                    return Object.entries(this.uncheckNumber).reduce((sum, item) => {
                        let has = child.some(it => it.title === item[0]);
                        if (has) {
                            sum = sum + parseFloat(item[1]);
                        }
                        return sum
                    }, 0)
                }
                return null
            },
            searchList() {
                if (this.searchText === '') {
                    return []
                }
                return this.menuList.reduce((ary, item) => {
                    let obj = {};
                    if (item.children && item.children.length) {
                        let arys = this.handleData(item.children, item.title);
                        ary = [...ary, ...arys]
                    } else {
                        if (item.title.indexOf(this.searchText) >= 0) {
                            obj = item;
                            ary.push(obj);
                        }
                    }
                    return ary
                }, [])
            },
        },
        watch: {
            visible() {
                this.searchText = ''
            }
        },
        methods: {
            toPage({path}) {
                this.$router.push({path});
                this.visible = false;
            },
            /**
             * 递归处理数据
             * @param item
             * @param title
             */
            handleData(item, title = '') {
                let array = [];
                let arys = [];
                if (item && item.length) {
                    arys = item.filter(i => i.title.indexOf(this.searchText) >= 0);
                    item.forEach(it => {
                        if (it.children && it.children.length) {
                            array = [...array, ...this.handleData(it.children, it.title)];
                        }
                    });
                    let newAry = [...array, ...arys];
                    newAry.forEach(i => {
                        if (i.p_title && i.p_title.length) {
                            if (i.p_title.indexOf(title) >= 0) return;
                            i.p_title.unshift(title)
                        } else {
                            i.p_title = [];
                            i.p_title.unshift(title);
                        }
                    });
                    return newAry
                } else {
                    return []
                }
            },
            /**
             * 处理菜单命令
             * @param command loginOut退出登录
             */
            handlerCommand(command) {
                if (command === 'editInfo') {
                    this.infoForm.name = this.userInfo.name;
                    this.infoForm.newUserName = this.userInfo.username;
                    this.showEditInfo = true;
                } else if (command === 'editPsd') {
                    this.showEditPsd = true;
                }
            },
            /**
             * 退出登录
             */
            @delToast('确认是否退出登录？')
            loginOut() {
                sessionStorage.clear();
                window.location.reload()
            },
            /**
             * 修改个人信息
             * @returns {Promise<void>}
             */
                @formVerify('editForm')
            async editInfo() {
                this.editing = true;
                let {newPassword, ...params} = this.infoForm;
                let [e, data] = await this.$api.updateMyApi(params);
                this.editing = false;
                if (e) return;
                if (data.status === 200) {
                    this.$store.state.user.userInfo.name = this.infoForm.name;
                    this.$store.state.user.userInfo.username = this.infoForm.newUserName;
                    this.showEditInfo = false;
                    this.$message.success(data.msg);
                } else {
                    this.$message.warning(data.msg)
                }
            },
            /**
             * 修改密码
             * @returns {Promise<void>}
             */
                @formVerify('editForm')
            async editPsd() {
                this.editing = true;
                let [e, data] = await this.$api.updateMyApi({newPassword: this.infoForm.newPassword});
                this.editing = false;
                if (e) return;
                if (data.status === 200) {
                    this.showEditPsd = false;
                    this.$message.success(data.msg);
                } else {
                    this.$message.warning(data.msg)
                }
            },
        }
    }
</script>
<style>
    .select-search-text {
        color: #ff4444 !important;
        font-weight: bold;
    }
</style>
<style scoped lang="less">
    .layout-right-nav {
        width: 100%;
        height: 70px;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #E0E4EC;

        .nav-line {
            width: 1px;
            height: 20px;
            margin: 0 30px;
            background-color: #ECECEC;
        }

        .nav-line-last {
            margin-left: 20px;
            margin-right: 0;
        }

        .nav-address {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 1;
            font-size: 14px;
            font-weight: 400;
            color: rgba(52, 57, 87, 1);

            .iconfont {
                color:  #5F9D9D;
                margin-right: 10px;
            }
        }

        .iconfont {
            font-size: 24px;
            color: #CFD3DD;
            cursor: pointer;
        }

        .nav-head {
            margin: 0 10px;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            background-color: #9AAFDF;
            flex-shrink: 0;

            img {
                width: 34px;
                height: 34px;
                border-radius: 100%;
                object-fit: cover;
            }
        }

        .el-dropdown-link {
            min-width: 40px;
            font-size: 14px;
            font-weight: bold;
            color: rgba(96, 106, 130, 1);
        }
    }

    .search-content {
        width: 400px;
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;

        .search-list {
            margin-top: 10px;

            .search-list-item {
                width: 100%;
                height: 40px;
                line-height: 40px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 10px;

                &:hover {
                    background-color: #EAFCFF;
                }
            }
        }
    }
</style>
