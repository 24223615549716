export default {
    path: '/price',
    name: 'price',
    icon: 'icon-caiwu',
    component: () => import('./../views/price/index'),
    title: '财务管理',
    children: [
        {
            path: '/price/order',
            name: 'price',
            icon: 'icon-fenxiao',
            component: () => import('../views/price/order'),
            title: '店铺订单',
        },
        {
            path: '/price/goodsOrder',
            name: 'price',
            icon: 'icon-tixian',
            component: () => import('../views/price/orderGoods'),
            title: '充值订单',
        },
        {
            path: '/price/setGoods',
            name: 'rank',
            icon: 'icon-tixian',
            component: () => import('../views/price/goods'),
            title: '充值设置',
        },
        {
            path: '/price/commission',
            name: 'commission',
            icon: 'icon-tixian',
            component: () => import('../views/price/commission'),
            title: '提成设置',
        },
        {
            path: '/price/coupon',
            name: 'coupon',
            icon: 'icon-tixian',
            component: () => import('../views/price/coupon'),
            title: '代金券列表',
        },{
            path: '/price/integralMall',
            name: 'integralMall',
            icon: 'icon-tixian',
            component: () => import('../views/price/integralMall'),
            title: '积分商品',
        },{
            path: '/price/convertLogList',
            name: 'convertLogList',
            icon: 'icon-tixian',
            component: () => import('../views/price/convertLogList'),
            title: '兑换记录',
        },
        {
            path: '/store/ruleAppointment',
            name: 'list',
            component: () => import('../views/store/full'),
            title: '实时库存',
        },
        {
            path: '/store/memberSet',
            name: 'memberSet',
            component: () => import('../views/store/memberSet'),
            title: '会员设置',
        },
		{
		    path: '/store/integralMall',
		    name: 'integralMall',
		    component: () => import('../views/store/integralMall'),
		    title: '积分商城',
		}
    ]
}
