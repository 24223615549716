import Vue from 'vue'
import Vuex from 'vuex'
import layout from './layout'
import user from './user'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    modules: ['layout', 'user']
});

export default new Vuex.Store({
    modules: {layout, user},
    state: {
        token: '',
        hasResRouter: false
    },
    mutations: {
        setToken(state, data){
            window.sessionStorage.setItem('token', data);
            state.token = data
        },
    },
    actions: {

    },
    plugins: [vuexLocal.plugin]
})
