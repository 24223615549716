import axios from './../utlis/axios'

////////////////////////////////////////////////////////////////
/**
 * 文章列表
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getArticleListApi(params) {
    try {
        let {data} = await axios.get('/admin/article/getArticleList', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 文章詳情
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getArticleOneApi(params) {
    try {
        let {data} = await axios.get('/admin/article/getArticleOne', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 添加文章
 * @param params
 * @returns {Promise<*[]>}
 */
export async function addArticleApi(params) {
    try {
        let {data} = await axios.post('/admin/article/addArticle', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 編輯文章
 * @param params
 * @returns {Promise<*[]>}
 */
export async function editArticleApi(params) {
    try {
        let {data} = await axios.post('/admin/article/editArticle', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 删除文章
 * @param params
 * @returns {Promise<*[]>}
 */
export async function delArticleApi(params) {
    try {
        let {data} = await axios.post('/admin/article/delArticle', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}
/**
 * 获取all文章
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getArticleAllApi(params) {
    try {
        let {data} = await axios.get('/admin/common/searchArticle', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}