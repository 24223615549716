export default {
	path: '/user',
	name: 'user',
	icon: 'icon-yonghu',
	component: () => import('./../views/user/index'),
	title: '用户管理',
	children: [{
			path: '/user/userList',
			name: 'user',
			icon: 'icon-yonhu',
			component: () => import('./../views/user/userList'),
			title: '用户列表',
		}, {
			path: '/user/memberList',
			name: 'member',
			icon: 'icon-yonhu',
			component: () => import('./../views/user/memberList'),
			title: '会员等级',
		}, {
			path: '/user/cardList',
			name: 'member',
			icon: 'icon-yonhu',
			component: () => import('./../views/user/cardList'),
			title: '学生王卡',
		},
		{
			path: '/user/storeUserList',
			name: 'user',
			icon: 'icon-yonhu',
			component: () => import('./../views/user/storeUserList'),
			title: '店铺用户',
		}
	]
}
