/**
 * 验证表单封装
 * @param formName
 * @return {Promise<any>}
 */
export function submitForm(formName) {
    return new Promise(((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                resolve()
            } else {
                reject()
            }
        });
    }))
}

export const validate = {
    /**
     * 电话验证
     * @param rule
     * @param value
     * @param callback
     */
    checkPhone(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else {
            let reg = /^1[3456789]\d{9}$/;
            if (!(reg.test(value))) {
                callback(new Error('请输入正确电话号码'));
            } else {
                callback()
            }
        }
    },
    /**
     * 只能输入数字
     * @param rule
     * @param value
     * @param callback
     */
    checkNumber(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else {
            let reg = /^\d+(\.\d+)?$/;
            if (!(reg.test(value))) {
                callback(new Error('请输入数字'));
            } else {
                callback()
            }
        }
    },
    /**
     * 设置最大时间480
     * @param rule
     * @param value
     * @param callback
     */
    checkMaxTime(rule, value, callback) {
        if (value>480) {
            callback(new Error('请输入小于480分钟的数'));
        } else {
            callback()
        }
    },
    /**
     * 只能输入0-9的数字
     * @param rule
     * @param value
     * @param callback
     */
    checkNumber2(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else {
            let reg = /^\d*(\.?\d{0,1})/;
            if (!(reg.test(value))) {
                callback(new Error('请输入数字'));
            } else {
                callback()
            }
        }
    },
    /**
     * 不能输入汉字
     * @param rule
     * @param value
     * @param callback
     */
    checkText(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else {
            let reg = /[\u4e00-\u9fa5]/gm;
            if (reg.test(value)) {
                callback(new Error('不能输入汉字'));
            } else {
                callback()
            }
        }
    },
    /**
     * 密码验证
     * @param rule
     * @param value
     * @param callback
     */
    checkPwd(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else if (value.length < 6) {
            callback(new Error('长度不能少于6位'));
        } else if (value.length > 20) {
            callback(new Error('长度不能大于20位'));
        } else {
            let reg = /[\u4e00-\u9fa5]/gm;
            if (reg.test(value)) {
                callback(new Error('不能输入汉字'));
            } else {
                callback()
            }
        }
    },
    /**
     * 8位验证
     * @param rule
     * @param value
     * @param callback
     */
    checkRechargeCode(rule, value, callback) {
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else if (value.length < 8) {
            callback(new Error('不能小于8位'));
        } else if (value.length > 8) {
            callback(new Error('不能大于8位'));
        }else {
            callback()
        }
    },
    /**
     * 检测账号
     * @param rule
     * @param value
     * @param callback
     */
    checkAccount(rule, value, callback) {
        let reg =/^[a-zA-Z][a-zA-Z0-9]{0,}$/i;
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else if (!reg.test(value)) {
            callback(new Error('字母开头，由数字或字母组成'));
        } else {
            callback()
        }
    },
    /**
     *  发票验证
     */
    checkInvoice(rule, value, callback){
        let reg =/^[a-zA-Z0-9]+$/;
        if (value === '' || value === null) {
            callback(new Error(rule.nullText || '不能为空'));
        } else if (!reg.test(value)) {
            callback(new Error('由数字或字母组成'));
        } else {
            callback()
        }
    },
    checkFuNum(rule, value, callback){
        if (value < 0) {
            callback(new Error('请输入大于0的数字'));
        }
        let reg = /^\d+$/;
        if(!reg.test(value)){
            callback(new Error('请输入数字'));
        }
        callback()
    },
	checkThanNum(rule, value, callback){
	    if (value <= 0) {
	        callback(new Error('请输入大于0的数字'));
	    }
	    let reg = /^\d+$/;
	    if(!reg.test(value)){
	        callback(new Error('请输入数字'));
	    }
	    callback()
	},
	checkPercentNum(rule, value, callback){
	    if (value < 0) {
	        callback(new Error('请输入大于0的数字'));
	    }
		if (value > 100) {
		    callback(new Error('请输入小于100的数字'));
		}
	    let reg = /^\d+$/;
	    if(!reg.test(value)){
	        callback(new Error('请输入数字'));
	    }
	    callback()
	},
	checkCardNum(rule, value, callback){
	    let reg = /^\d+$/;
	    if(!reg.test(value)){
	        callback(new Error('请输入数字'));
	    }else if (value.length != 8) {
	        callback(new Error('请输入8位数字'));
	    }
	    callback()
	},
    checkIsNum(rule, value, callback){
        let reg = /^[-0-9]\d{0,}$/;
        if(!reg.test(value)){
            callback(new Error('请输入数字'));
        }
        callback()
    },
};
