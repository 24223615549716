import axios from './../utlis/axios'



///////////////////////////////////////////
export async function storeUser(params) {
    try {
        let {data} = await axios.get('/admin/storeUser', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
/////////////////店铺账号//////////////////
export async function storeUserList(params) {
    try {
        let {data} = await axios.get('/admin/storeUserList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//店铺账号列表-不分页
export async function storeUserLists(params) {
    try {
        let {data} = await axios.get('/admin/storeUserLists', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//修改
export async function storeUserSave(params) {
    try {
        let {data} = await axios.post('/admin/storeUserSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//添加
export async function storeUserCreate(params) {
    try {
        let {data} = await axios.post('/admin/storeUserCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//删除
export async function storeUserDel(params) {
    try {
        let {data} = await axios.post('/admin/storeUserDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


////////////////////////////////////////
////////////店铺///////////////////////
//列表
export async function storeList(params) {
    try {
        let {data} = await axios.get('/admin/storeList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
export async function storeLists(params) {
    try {
        let {data} = await axios.get('/admin/storeLists', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加
export async function createStore(params) {
    try {
        let {data} = await axios.post('/admin/createStore', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改
export async function saveStore(params) {
    try {
        let {data} = await axios.post('/admin/saveStore', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除
export async function delStore(params) {
    try {
        let {data} = await axios.post('/admin/delStore', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//单价列表
export async function reservePrice(params) {
    try {
        let {data} = await axios.get('/admin/reservePrice', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//单价添加
export async function reservePriceCreate(params) {
    try {
        let {data} = await axios.post('/admin/reservePriceCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//单价修改
export async function reservePriceSave(params) {
    try {
        let {data} = await axios.post('/admin/reservePriceSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//单价删除
export async function reservePriceDel(params) {
    try {
        let {data} = await axios.post('/admin/reservePriceDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//预定时间列表
export async function reserveList(params) {
    try {
        let {data} = await axios.get('/admin/reserveList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//单价添加
export async function reserveCreate(params) {
    try {
        let {data} = await axios.post('/admin/reserveCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//单价修改
export async function reserveSave(params) {
    try {
        let {data} = await axios.post('/admin/reserveSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//单价删除
export async function reserveDel(params) {
    try {
        let {data} = await axios.post('/admin/reserveDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//已满
export async function reserveFull(params) {
    try {
        let {data} = await axios.get('/admin/reserveFull', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//已满添加
export async function reserveFullCreate(params) {
    try {
        let {data} = await axios.post('/admin/reserveFullCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//已满删除
export async function reserveFullDel(params) {
    try {
        let {data} = await axios.post('/admin/reserveFullDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//下载二维码
export async function storeCode(params) {
    try {
        let {data} = await axios.post('/admin/storeCode', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//下载二维码
export async function getThemesTime(params) {
    try {
        let {data} = await axios.post('/admin/getThemesTime', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//搜索门店
export async function searchStoreListApi(params) {
    try {
        let {data} = await axios.get('/admin/common/searchStoreList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//搜索门店
export async function searchStoreRoomListApi(params) {
    try {
        let {data} = await axios.get('/admin/common/searchStoreRoomList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取店铺营业时间
export async function getStoreTimeInfoApi(params) {
    try {
        let {data} = await axios.get('/admin/common/getStoreTimeInfo', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}