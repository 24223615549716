import Vue from 'vue';
import * as setting from "./setting";
import * as user from "./user";
import * as admin from "./admin";
import * as active from "./store";
import * as price from "./price";
import * as system from "./system";
import * as store from "./store";
import * as theme from "./theme";
import * as article from "./article";
import * as member from "./member";
import * as achievement from "./achievement";
import * as room from "./room";

const api = {
    ...setting,
	...user,
	...admin,
	...active,
	...price,
	...system,
	...store,
	...theme,
	...article,
	...member,
	...achievement,
	...room
};

Vue.prototype.$api = api;
