import axios from './../utlis/axios'



///////////////////////////////////////////
/////////////////房间//////////////////
export async function roomList(params) {
    try {
        let {data} = await axios.get('/admin/roomList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//添加
export async function roomCreate(params) {
    try {
        let {data} = await axios.post('/admin/roomCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//修改
export async function roomSave(params) {
    try {
        let {data} = await axios.post('/admin/roomSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}



//删除
export async function roomDel(params) {
    try {
        let {data} = await axios.post('/admin/roomDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//状态修改
export async function roomSaveStatus(params) {
    try {
        let {data} = await axios.post('/admin/roomSaveStatus', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//房间用户
export async function roomUserList(params) {
    try {
        let {data} = await axios.get('/admin/roomUserList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加用户
export async function roomUserAdd(params) {
    try {
        let {data} = await axios.post('/admin/roomUserAdd', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除用户
export async function roomUserDel(params) {
    try {
        let {data} = await axios.post('/admin/roomUserDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//房间成就
export async function roomAchievement(params) {
    try {
        let {data} = await axios.get('/admin/roomAchievement', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加房间成就
export async function roomAchievementAdd(params) {
    try {
        let {data} = await axios.post('/admin/roomAchievementAdd', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除成就
export async function roomAchievementDel(params) {
    try {
        let {data} = await axios.post('/admin/roomAchievementDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//房间用户
export async function roomUser(params) {
    try {
        let {data} = await axios.get('/admin/roomUser', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//房间评论
export async function roomMessage(params) {
    try {
        let {data} = await axios.get('/admin/roomMessage', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//房间评论
export async function replyMessage(params) {
    try {
        let {data} = await axios.post('/admin/replyMessage', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//评论删除
export async function delMessage(params) {
    try {
        let {data} = await axios.post('/admin/delMessage', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
export async function addptMessage(params) {
    try {
        let {data} = await axios.post('/admin/addptMessage', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取房间用户成就列表
export async function getUserAchievementListApi(params) {
    try {
        let {data} = await axios.get('/admin/room/getUserAchievementList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取房间所有成就
export async function getRoomAchievementAllApi(params) {
    try {
        let {data} = await axios.get('/admin/room/getRoomAchievementAll', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//指定房间用户发放成就
export async function giveUserAchievementApi(params) {
    try {
        let {data} = await axios.post('/admin/room/giveUserAchievement', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//取消指定房间用户成就
export async function cancelUserAchievementApi(params) {
    try {
        let {data} = await axios.post('/admin/room/cancelUserAchievement', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取房间详情
export async function getRoomInfoApi(params) {
    try {
        let {data} = await axios.get('/admin/room/getRoomInfo', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//开关房间
export async function openRoomApi(params) {
    try {
        let {data} = await axios.post('/admin/room/openRoom', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取房间风采列表
export async function getRoomSpecialListApi(params) {
    try {
        let {data} = await axios.get('/admin/room/getRoomSpecialList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加房间风采
export async function addRoomSpecialApi(params) {
    try {
        let {data} = await axios.post('/admin/room/addRoomSpecial', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑房间风采
export async function editRoomSpecialApi(params) {
    try {
        let {data} = await axios.post('/admin/room/editRoomSpecial', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除房间风采
export async function delRoomSpecialApi(params) {
    try {
        let {data} = await axios.post('/admin/room/delRoomSpecial', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改房间单个字段值
export async function renewRoomOneApi(params) {
    try {
        let {data} = await axios.post('/admin/room/renewRoomOne', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}