import Vue from 'vue'
import Router from 'vue-router'
import layout from '../views/layout/index'
import setting from './setting'
import user from './user'
import theme from './theme'
import price from './price'
import store from './store'
import system from './system'
import achievement from './achievement'
import room from './room'

Vue.use(Router);

let allRouter = [setting,user,store,theme,price,system,achievement,room];


//  整合各个模块
export const routerMoudleList = allRouter;
console.log('所有路由',routerMoudleList);
export const tempRouterMoudleList = JSON.parse(JSON.stringify(allRouter));
// 总路由表
export const routerAddList = {
    path: '/',
    name: 'layout',
    component: layout,
    children: routerMoudleList
};

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('./../views/login/index')
        }
    ]
})

