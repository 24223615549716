import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import HgDialog from './components/HgDialog';
import echarts from  'echarts';
import BaiduMap from 'vue-baidu-map'

import {submitForm,validate} from './utlis/formSubmit'
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.less';
import './style/element-variables.scss';
import './utlis/global'
import './utlis/mixins'
import './api/index'
import './api/downLoad'



Vue.config.silent = true;  // 静默模式，取消vue所有警告
Vue.config.productionTip = false;
Vue.prototype.$submitForm = submitForm;
Vue.prototype.$validate = validate;
Vue.prototype.$event=new Vue();

Vue.use(ElementUI);

Vue.component('HgDialog', HgDialog);
Vue.component('echarts', echarts);
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '8325164e247e15eea68b59e89200988b'
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
