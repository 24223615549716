<template>
    <div class="layout-content">
        <div class="layout-left" :style="{width: `${leftWidth}px`}">
            <div class="layout-logo" >
                <img src="../../assets/logo1.png" alt="">
            </div>
            <MenuList :isMobile="leftWidth<178"/>
        </div>
        <div class="layout-right">
            <LayoutNav :isMobile="leftWidth<178"/>
            <router-view :isMobile="leftWidth<178" class="layout-view"/>
        </div>
    </div>
</template>

<script>
    import MenuList from './MenuList'
    import LayoutNav from './LayoutNav'
    const {body} = document;
    const WIDTH = 1300; // refer to Bootstrap's responsive design
    export default {
        name: "layout",
        components: {MenuList, LayoutNav},
        beforeMount() {
            this.resizeHandler();
            window.addEventListener('resize', this.resizeHandler)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeHandler)
        },
        data(){
            return {
                leftWidth: 178
            }
        },
        methods:{
            isMobile() {
                const rect = body.getBoundingClientRect();
                return rect.width - 1 < WIDTH
            },
            resizeHandler() {
                this.leftWidth = this.isMobile() ? 80 : 178;
            },
        }
    }
</script>

<style scoped lang="less">
    @main-color: #5F9D9D;
    .layout-content{
        width: 100%;
        height: 100%;
        background-color: #EEF1F6;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        overflow-x: hidden;
        .layout-left{
            width: 170px;
            height: 100%;
            flex-shrink: 0;
            background-color: #fff;
            border-right: 1px solid #E0E4EC;
            transition: 0.3s;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
                background-color: #EFF2F7;
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: fade(@main-color, 20%);
                }
                &::-webkit-scrollbar {
                    background-color: @main-color;
                }
            }
            .layout-logo{
                height: 70px;
                line-height: 70px;
                font-size: 30px;
                font-weight: bold;
                //background: linear-gradient(0, #00B9D9, #00B9D9);
                background-color: @main-color;
                color: white;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                position: sticky;
                top: 0;
                left: 0;
                z-index: 6;
                img{
                    width: 60%;
                    height: 70px;
                    min-width: 70px;
                    object-fit: contain;
                }
            }
        }
        .layout-right{
            flex: 1;
            width: calc(~'100% - 210px');
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-flow: column;
            .layout-view{
                flex: 1;
                width: 100%;
                overflow: auto;
                /*overflow-x: hidden;*/
                &::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background-color: #EFF2F7;
                    cursor: pointer;
                }
                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: fade(@main-color, 60%);
                    }
                    &::-webkit-scrollbar {
                        background-color: #F5F5F5;
                    }
                }
            }
        }
    }
</style>
