import axios from './../utlis/axios'





// 会员问题
export async function vipQA(params) {
    try {
        let {data} = await axios.get('/admin/vip/q-a', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

// 会员问题编辑
export async function saveVipQA(params) {
    try {
        let {data} = await axios.post('/admin/save/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

// 会员问题创建
export async function createVipQA(params) {
    try {
        let {data} = await axios.post('/admin/create/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员问题删除
export async function delVipQA(params) {
    try {
        let {data} = await axios.post('/admin/del/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//用户常见问题
export async function userQA(params) {
    try {
        let {data} = await axios.get('/admin/common/q-a', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加常见问题
export async function userCreateQA(params) {
    try {
        let {data} = await axios.post('/admin/common/create/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑常见问题
export async function userSaveQA(params) {
    try {
        let {data} = await axios.post('/admin/common/save/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除常见问题
export async function userDelQA(params) {
    try {
        let {data} = await axios.post('/admin/common/del/q-a', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员权益
export async function vipIntroduce(params) {
    try {
        let {data} = await axios.get('/admin/vip/introduce', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员权益添加
export async function vipIntroduceCreate(params) {
    try {
        let {data} = await axios.post('/admin/create/introduce', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员权益编辑
export async function vipIntroduceSave(params) {
    try {
        let {data} = await axios.post('/admin/save/introduce', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员权益删除
export async function vipIntroduceDel(params) {
    try {
        let {data} = await axios.post('/admin/del/introduce', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//分销规则
export async function distributionRules(params) {
    try {
        let {data} = await axios.get('/admin/distribution/rules', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员规则添加
export async function distributionRulesCreate(params) {
    try {
        let {data} = await axios.post('/admin/distribution/rules/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员规则编辑
export async function distributionRulesSave(params) {
    try {
        let {data} = await axios.post('/admin/distribution/rules/save', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//会员权益删除
export async function distributionRulesDel(params) {
    try {
        let {data} = await axios.post('/admin/distribution/rules/del', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//用户建议类型

export async function messageType(params) {
    try {
        let {data} = await axios.get('/admin/message/type', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//添加分类
export async function messageTypeCreate(params) {
    try {
        let {data} = await axios.post('/admin/message/type/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//编辑分类
export async function messageTypeSave(params) {
    try {
        let {data} = await axios.post('/admin/message/type/save', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除分类
export async function messageTypeDel(params) {
    try {
        let {data} = await axios.post('/admin/message/type/del', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//用户建议
export async function getUserMessage(params) {
    try {
        let {data} = await axios.get('/admin/user/message', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//用户建议删除
export async function delUserMessage(params) {
    try {
        let {data} = await axios.post('/admin/user/message/del', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//l轮播
export async function getBanner(params) {
    try {
        let {data} = await axios.get('/admin/banner', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//轮播添加
export async function createBanner(params) {
    try {
        let {data} = await axios.post('/admin/banner/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//轮播修改
export async function saveBanner(params) {
    try {
        let {data} = await axios.post('/admin/banner/save', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//轮播删除
export async function delBanner(params) {
    try {
        let {data} = await axios.post('/admin/banner/del', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//短信列表
export async function smsList(params) {
    try {
        let {data} = await axios.get('/admin/sms/list', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//发送短信
export async function createSMS(params) {
    try {
        let {data} = await axios.post('/admin/sms/create', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除短信
export async function delSMS(params) {
    try {
        let {data} = await axios.post('/admin/sms/del', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//获取轮播
export async function getTypeList(params) {
    try {
        let {data} = await axios.post('/admin/getTypeList', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//系统配置
export async function getConfigListApi(params) {
    try {
        let {data} = await axios.get('/admin/config/getConfigList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//更改配置
export async function renewConfigApi(params) {
    try {
        let {data} = await axios.post('/admin/config/renewConfig', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
