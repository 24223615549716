import axios from './../utlis/axios'

////////////////////////////////////////////////////////////////
/**
 * 管理员列表
 * @param params
 * @returns {Promise<*[]>}
 */
export async function adminList(params) {
    try {
        let {data} = await axios.get('/admin/admin-list', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 角色列表
 * @param params
 * @returns {Promise<*[]>}
 */
export async function getRoleLists(params) {
    try {
        let {data} = await axios.get('/admin/role-lists', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 管理员编辑
 * @param params
 * @returns {Promise<*[]>}
 */
export async function saveAdmin(params) {
    try {
        let {data} = await axios.post('/admin/admin-save', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 管理员新增
 * @param params
 * @returns {Promise<*[]>}
 */
export async function createAdmin(params) {
    try {
        let {data} = await axios.post('/admin/admin-create', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 删除管理员
 * @param params
 * @return {*}
 */
export async function delAdmin(params) {
    try {
        let {data} = await axios.post('/admin/admin-del', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

//////////////////////////////////////////////////////////////

/**
 * 获取角色列表
 * @param params
 * @return {*}
 */
export async function roleListAll(params) {
    try {
        let {data} = await axios.get('/admin/role/list', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 获取全部路由
 * @param params
 * @return {*}
 */
export async function menuListAll(params) {
    try {
        let {data} = await axios.get('/admin/menu/list', {params});
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 添加角色
 * @param params
 * @return {*}
 */
export async function createRole(params) {
    try {
        let {data} = await axios.post('/admin/role/create', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 编辑角色
 * @param params
 * @return {*}
 */
export async function saveRole(params) {
    try {
        let {data} = await axios.post('/admin/role/save', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

/**
 * 编辑权限
 * @param params
 * @return {*}
 */
export async function saveRoleMenu(params) {
    try {
        let {data} = await axios.post('/admin/role/save/menu', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}


/**
 * 删除角色
 * @param params
 * @return {*}
 */
export async function delRole(params) {
    try {
        let {data} = await axios.post('/admin/role/del', params);
        return [null, data]
    } catch (e) {
        return [e, null]
    }
}

