export default {
	path: '/achievement',
	name: 'achievement',
	icon: 'icon-chanpin',
	component: () => import('../views/achievement/index'),
	title: '成就管理',
	children: [{
		path: '/achievement/list',
		name: 'list',
		icon: 'el-icon-user-solid',
		component: () => import('../views/achievement/list'),
		title: '成就列表',
	},{
		path: '/achievement/rankingList',
		name: 'list',
		icon: 'el-icon-s-data',
		component: () => import('../views/achievement/rankingList'),
		title: '排行榜',
	}]
}
