export default {
    path: '/setting',
    name: 'setting',
    icon: 'icon-xitongshezhi',
    component: () => import('./../views/setting/index'),
    title: '系统设置',
    children: [{
        path: '/setting/userManage',
        name: 'setting',
        icon: 'icon-guanliyuan',
        component: () => import('./../views/setting/userManage'),
        title: '管理员管理',
    }, {

        path: '/setting/storeUser',
        name: 'list',
        icon: 'el-icon-user-solid',
        component: () => import('../views/setting/storeManage'),
        title: '店铺账号',
    },
        {
            path: '/setting/roleManage',
            name: 'setting',
            icon: 'icon-role',
            component: () => import('./../views/setting/roleManage'),
            title: '角色管理',
        }, {
            path: '/setting/authManage',
            name: 'setting',
            icon: 'icon-quanxian',
            component: () => import('./../views/setting/authManage'),
            title: '权限管理',
        },

        {
            path: '/setting/banner',
            name: 'banner',
            icon: 'icon-lunbo',
            component: () => import('../views/setting/banner'),
            title: '轮播管理',
        },
        {
            path: '/setting/article',
            name: 'article',
            icon: 'icon-neirongguanli',
            component: () => import('./../views/setting/article'),
            title: '文章管理',
        },
		{
		    path: '/setting/configList',
		    name: 'configList',
		    icon: 'icon-neirongguanli',
		    component: () => import('./../views/setting/configList'),
		    title: '系统配置',
		},
        {
            path: '/setting/webSet',
            name: 'webSst',
            icon: 'icon-neirongguanli',
            component: () => import('./../views/setting/webSet'),
            title: '內容协议',
        }
    ]
}

