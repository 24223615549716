<template>
    <div class="menu-list">
        <template v-for="(item,index) in menuList">
            <el-tooltip class="item" effect="dark" :disabled="!isMobile" :content="item.title" placement="right">
                <div :key="index"
                     v-if="!item.hide"
                     @click="toDetail(item)"
                     :class="{active: $route.path.indexOf(item.path)===0}"
                     class="menu-list-item">
                    <i class="iconfont" :class="item.icon"></i>
                    <!-- <el-badge  :value="getNum(item.title)"   class="item"> -->
                    <div v-if="!isMobile" style="line-height: 1;">{{item.title}}</div>
                    </el-badge>
                </div>
            </el-tooltip>

        </template>

    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'MenuItem',
        data(){
             return{
                 num:0
             }

        },
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            ...mapState('layout', {
                menuList: state => state.menuList
            })
        },
        mounted() {
            // this.getNewMake();
        },
        methods: {
            toDetail({path}) {
                this.$router.push({path})
            },
            getNum(tile){
                if(tile == '财务管理'){
                    return  this.num;
                }else{
                    return  null;
                }
            },
            sleep(time) {
                return new Promise((resolve => {
                    setTimeout(() => {
                        resolve();
                    }, time * 1000)
                }))
            },
            async getNewMake() {
                let [e, data] = await this.$api.orderDispose();
                if (e) return;
                if (data.code === 200) {
                    this.num=data.data.data;
                }
                await this.sleep(5);
                this.getNewMake();

            },

        }
    }
</script>

<style scoped lang="less">
    @main-color: #5F9D9D;
    .menu-list {
        .iconfont{
            font-size: 22px;
            margin-right: 20px;
            color: #8FA1B3;
            font-weight: 100;
        }
        .menu-list-item {
            width: 100%;
            height: 44px;
            line-height: 44px;
            padding-left: 30px;
            box-sizing: border-box;
            margin: 18px 0;
            cursor: pointer;
            font-size: 16px;
            outline: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /*font-weight: bold;*/
            color: rgba(15, 18, 33, 1);

            &:hover {
                background-color: #EAFCFF;
            }
        }

        .active {
            position: relative;
            background-color: #EAFCFF;
            color: @main-color !important;
            font-weight: bold;
            .iconfont{
                color: @main-color !important;
            }
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 44px;
                top: 0;
                left: 0;
                background-color: @main-color;
            }
        }
    }
</style>
