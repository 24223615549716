export default {
    path: '/store',
    name: 'product',
    icon: 'icon-chanpin',
    component: () => import('../views/store/index'),
    title: '店铺管理',
    children: [
        {
        path: '/store/list',
        name: 'list',
        icon: 'el-icon-user-solid',
        component: () => import('../views/store/list'),
        title: '店铺列表',
    },
        {
            path: '/store/price',
            name: 'list',
            icon: 'el-icon-user-solid',
            component: () => import('../views/store/price'),
            title: '票价设置',
        },
        {
            path: '/store/appointment',
            name: 'list',
            icon: 'el-icon-user-solid',
            component: () => import('../views/store/appointment'),
            title: '预约设置',
        }
        // {
        //     path: '/store/ruleAppointment',
        //     name: 'list',
        //     icon: 'el-icon-user-solid',
        //     component: () => import('../views/store/full'),
        //     title: '实时库存',
        // },
    ]
}
