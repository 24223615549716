import Vue from 'vue'
import router from '../router/index'
import store from '../store/index'
import fa from "element-ui/src/locale/lang/fa";
// 金额过滤器

router.beforeEach(async (to, from, next) => {
    let token = sessionStorage.getItem('token');
    if (token) {
        if (to.path === '/login') { // 如果有token直接登录
            next('/')
        } else {
            if (!store.state.hasResRouter) {   // 判断是否加载过路由
                store.state.hasResRouter = true;
                store.commit('setToken', token); // 更新store中的token
                let [e, route] = await store.dispatch('layout/getListByUserApi');// 从接口获取用户路由并进行筛选
                router.addRoutes(route);  //将筛选的动态加载到路由中
                next({...to, replace: true})
            } else {
                next()
            }
        }
    } else if (to.path !== '/login') {  //没有登录跳登录页面
        next('/login');
    } else {
        next();
    }
});

/**
 * base64转二进制
 * @param code
 * @returns {Blob}
 */
function base64ToBlob(code) {
    let parts = code.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for(let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
}

function downloadChart(fileName, content) {
    let time = getNowTimeString().split('-').join('');
    let aLink = document.createElement('a');
    let blob = base64ToBlob(content); // new Blob([content]);
    let evt = document.createEvent('HTMLEvents');
    evt.initEvent('click', true, true);// initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = `${fileName}${time}`;
    aLink.href = URL.createObjectURL(blob);
    aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));// 兼容火狐
}

/**
 * 下载图片
 * @param fileName
 * @param content
 */
function downloadImg(fileName, content) {
    let time = getNowTimeString().split('-').join('');
    let aLink = document.createElement('a');
    let evt = document.createEvent('HTMLEvents');
    evt.initEvent('click', true, true);// initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = `${fileName}${time}`;
    aLink.href = content;
    aLink.target = '_blank';
    aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));// 兼容火狐
}

/**
 * 获取当前时间字符串
 * @param formatDate
 * @returns {string}
 */
function getNowTimeString(formatDate){
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    if(formatDate){
      return `${year}${month}${day}`
    }else {
      return `${year}-${month}-${day}`
    }

}
function contrastTime(val){
    if(!val) return false;
    let now = new Date();
    let time = new Date(val);
    return now - time <= 1800 * 1000;
}
Vue.prototype.$base64ToBlob = base64ToBlob;
Vue.prototype.$downloadImg = downloadImg;
Vue.prototype.$downloadChart = downloadChart;
Vue.prototype.$timeString = getNowTimeString;
Vue.prototype.$contrastTime = contrastTime;


