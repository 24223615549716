/**
 * 防抖函数
 * @param time 默认500ms
 * @return {function(*, *, *): *}
 */
export function debounce(time = 500) {
    const instanceMap = new Map();
    return function (obj, name, describe) {
        let oldVal = describe.value;
        describe.value = function () {
            clearTimeout(instanceMap.get('timeOut'));
            instanceMap.set('timeOut', setTimeout(() => {
                return oldVal.apply(this, arguments)
            }, time))
        };
        return describe
    }
}

/**
 * 验证表单
 * @param formName ref的名称
 * @return {Function}
 */
export function formVerify(formName = 'forms') {
    return function (obj, name, describe) {
        let oldVal = describe.value;
        describe.value = async function () {
            try {
                await this.$submitForm(formName);
                return oldVal.apply(this, arguments);
            } catch (e) {
                return e
            }
        };
        return describe
    }
}

/**
 * 删除提示
 * @param text  删除提示文字
 * @return {function(*, *, *): *}
 */
export function delToast(text = '此操作将永久删除该项, 是否继续?') {
    return function (obj, name, describe) {
        let oldVal = describe.value;
        describe.value = async function () {
            try {
                await this.$confirm(text, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                return oldVal.apply(this, arguments);
            } catch (e) {
                return ''
            }
        };
        return describe
    }
}

/**
 * 分销
 * @param text
 * @returns {function(*, *, *): *}
 */
export function ascertain(text = '确定分销奖励?') {
    return function (obj, name, describe) {
        let oldVal = describe.value;
        describe.value = async function () {
            try {
                await this.$confirm(text, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                return oldVal.apply(this, arguments);
            } catch (e) {
                return ''
            }
        };
        return describe
    }
}
/**
 *
 * @param text  删除提示文字
 * @return {function(*, *, *): *}
 */
export function saveToast(text = '请再次确认, 是否继续?') {
    return function (obj, name, describe) {
        let oldVal = describe.value;
        describe.value = async function () {
            try {
                await this.$confirm(text, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                return oldVal.apply(this, arguments);
            } catch (e) {
                return ''
            }
        };
        return describe
    }
}
