import axios from './../utlis/axios'



///////////////////////////////////////////
/////////////////主题//////////////////
export async function themeList(params) {
    try {
        let {data} = await axios.get('/admin/themeList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//主题列表-不分页
export async function themeLists(params) {
    try {
        let {data} = await axios.get('/admin/themeLists', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//修改
export async function saveTheme(params) {
    try {
        let {data} = await axios.post('/admin/saveTheme', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


//添加
export async function createTheme(params) {
    try {
        let {data} = await axios.post('/admin/createTheme', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//删除
export async function delTheme(params) {
    try {
        let {data} = await axios.post('/admin/delTheme', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}


////////////////////////////////////////
////////////店铺主题///////////////////////
//列表
export async function storeTheme(params) {
    try {
        let {data} = await axios.get('/admin/storeTheme', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
export async function storeThemeList(params) {
    try {
        let {data} = await axios.get('/admin/storeThemeList', {params});
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}

//添加
export async function storeThemeCreate(params) {
    try {
        let {data} = await axios.post('/admin/storeThemeCreate', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//修改
export async function storeThemeSave(params) {
    try {
        let {data} = await axios.post('/admin/storeThemeSave', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
//删除
export async function storeThemeDel(params) {
    try {
        let {data} = await axios.post('/admin/storeThemeDel', params);
        return [null, data]
    }catch (e) {
        return [e, null]
    }
}
