import Vue from 'vue'
import axios from './../utlis/axios'

Vue.prototype.$upload = 'https://mstt2.hw.hanguosoft.com';

let downTime = null;
let canDownLoad = true;

function sleep(time = 1000){
  return new Promise(((resolve, reject) => {
    setTimeout(()=>{
      resolve()
    }, time)
  }))
}


async function downFile(url, params, filename, type = 'xlsx') {
  if (!canDownLoad) {
    Vue.prototype.$message.warning('操作过快');
    return
  }
  canDownLoad = false;
  downTime = setTimeout(() => {
    canDownLoad = true;
  }, 3000);
  let res = await axios({responseType: 'blob', method: 'GET', url, params});
  const link = document.createElement('a');
  let typeStr = '';
  if (type === 'doc') {
    typeStr = 'application/msword'
  } else if (type === 'docx') {
    typeStr = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  }else if(type === 'pdf'){
    typeStr = 'application/pdf'
  }else if(type === 'xls'){
    typeStr = 'application/vnd.ms-excel';
  }else if(type === 'xlsx'){
    typeStr = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  let blob = new Blob([res.data], {type: typeStr});
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.${type}`;//下载后文件名
  link.click()
}
async function downUrl(url) {
  const link = document.createElement('a');
  link.style = 'display:none';
  link.href = `${Vue.prototype.$upload}${url}`;
  link.click()
}


Vue.prototype.$downFile = downFile;
Vue.prototype.$downUrl = downUrl;
Vue.prototype.$sleep = sleep;
