import axios from 'axios'
import Vue from 'vue'
import {Message} from "element-ui";

let times = null;
let isShow = false;
let is500 = false;
const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '' : '/', // api 的 base_url
    timeout: 30000, // 超时时间
    headers: {}
});
service.interceptors.request.use((config) => {   // 请求拦截器
    let token = window.sessionStorage.getItem('token');


    config.headers['Authorization'] = `Bearer ${token}`;
    return config
});
service.interceptors.response.use((response) => {  // 响应拦截器
    if (response.data.code === 401) {  // 401重新登录
        if (!isShow) {
            isShow = true;
            Vue.prototype.$alert(response.data.msg, '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                    sessionStorage.clear();
                    window.location.reload();
                }
            });
        }
    } else {
        return response
    }
}, error => {
    if (error.response) {
        switch (error.response.status) {  // 状态处理

            case 400:
                Message.error(error.response.data.msg)
                return Promise.reject(error.response);
            case 401:
                if (!isShow) {
                    isShow = true;
                    Vue.prototype.$alert(error.response.data.msg, '温馨提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            sessionStorage.clear();
                            window.location.reload();
                        }
                    });
                }
                break;
            case 404:
                return Promise.reject(error.response);
            case 500:
                if (!is500) {
                    is500 = true;
                    setTimeout(() => {
                        is500 = false
                    }, 3000);
                    Vue.prototype.$message.warning('服务器繁忙');
                }
                break;
        }
    }
    return Promise.reject(error)
});
export default service;
