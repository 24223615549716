import Vue from 'vue'

Vue.mixin({
    computed: {
        getRoleBtn() {
            let btnList = [];
            function handList(array, path) {
                array.forEach(item => {
                    if (path === item.path) {
                        btnList = item.btn;
                    } else {
                        if (item.children && item.children.length) {
                            handList(item.children, path)
                        }
                    }
                })
            }

            let list = this.$store.state.layout.menuList;
            handList(list, this.$route.path);
            return btnList;
        },
        isPmc(){
            return !this.$store.state.user.userInfo.parkId
        }
    },
    methods: {
        checkRoleBtn(str) {
            return this.getRoleBtn.some(item => item.buttonName === str)
        },
        handWhell(event) {
            if (this.$refs.pageContent.clientWidth < this.$refs.pageContent.scrollWidth) {
                event.preventDefault();
                this.$refs.pageContent.scrollLeft += event.deltaY
                    ? event.deltaY
                    : (event.detail && event.detail !== 0)
                        ? event.detail
                        : -event.wheelDelta;
            }
        },
    },
    directives: {
        roleBtn: {
            bind(el, binding, vnode) {
                if (!binding.value) {
                    switch (binding.arg) {
                        case 'gray':
                            let mask = document.createElement('div');
                            mask.className = 'pointer-events-child';
                            mask.title = '此操作无权限';
                            mask.onclick = function (e) {
                                e.stopPropagation();
                            };
                            el.dataset.has = '1';
                            el.style.filter = 'grayscale(1)';
                            el.style.pointerEvents = 'none';
                            el.style.position = 'relative';
                            el.append(mask);
                            break;
                        case 'hide':
                            el.style.display = 'none';
                            break;
                        default :
                            el.style.display = 'none';
                            break;
                    }
                }
            },
            update(el, binding, vnode) {
                if (!binding.value) {
                    switch (binding.arg) {
                        case 'gray':
                            let mask = document.createElement('div');
                            mask.className = 'pointer-events-child';
                            mask.title = '此操作无权限';
                            mask.onclick = function (e) {
                                e.stopPropagation();
                            };
                            el.style.filter = 'grayscale(1)';
                            el.style.position = 'relative';
                            el.style.pointerEvents = 'none';
                            if (el.dataset.has < 1) {
                                el.append(mask);
                            }
                            break;
                        case 'hide':
                            el.style.display = 'none';
                            break;
                        default :
                            el.style.display = 'none';
                            break;
                    }
                }
            },
            unbind(el, binding, vnode) {

            }
        },
        load:{
            bind(el, binding, vnode){
                el.style.position = 'relative';
                let mask = document.createElement('div');
                mask.className = 'load-div-content';
                let icon = document.createElement('i');
                icon.className='el-icon-loading';
                mask.appendChild(icon);
                el.appendChild(mask);
                el.mask = mask;
            },
            update(el, binding, vnode) {
                if (binding.value) {
                    el.style.pointerEvents = 'none';
                    el.mask.style.display = 'flex'
                }else {
                    el.style.pointerEvents = 'auto';
                    el.mask.style.display = 'none'
                }
            },
        }
    }
});
