import axios from "../utlis/axios";
let data = {
    data:{
        token: 'token',
        user: {
            name: '本地'
        },
        todoNumber: {},
        uncheckNumber: {}
    }
};
export default {
    namespaced: true,
    state:{
        userInfo: ''
    },
    actions:{
        async loginApi({commit, dispatch, state}, params){
            try {
                let {data} = await axios.post('/admin/auth/login', params);


                if(data.code === 200){
                    commit('setUserInfo', data.user);
                    commit('setToken', data.token, {root: true});
                    window.sessionStorage.setItem('type', data.user.type);
                }
                return [null, data]
            }catch (e) {
                return [e, null]
            }
        },

    },
    mutations: {
        setUserInfo(state, data){
            state.userInfo = data;
        },
    },
}
